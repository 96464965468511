// pages/404.js
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const Custom404 = () => {
    const router = useRouter();

    useEffect(() => {
        // Redirect to homepage
        router.push('/');
    }, []);

    return null; // Return null because we don't want to render anything
};

export default Custom404;
